var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Endereço")]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.logradouroAtual))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.numeroAtual))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.bairroAtual))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.cidadeAtual))])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.cepAtual))])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.estadoAtual))])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.referenciaAtual))])]),_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.InformacaoAdicionalAtual))])]),_c('div',{staticClass:"row"},[_vm._m(8),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.latitudeAtual))])]),_c('div',{staticClass:"row"},[_vm._m(9),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.longitudeAtual))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Logradouro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Número")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Bairro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Cidade")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("CEP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Estado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Referencia")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Informação adicional")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Latitude")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Longitude")])])
}]

export { render, staticRenderFns }
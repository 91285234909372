var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Detalhes")]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.nomeAtual))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.tipoAtual))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.contaAtual))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.telefoneAtual))])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.celularAtual))])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.emailAtual))])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm._f("booleanParaSimNao")(_vm.pjAtual)))])]),_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.cpfCnpj))])]),_c('div',{staticClass:"row"},[_vm._m(8),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.criadoEmAtual))])]),_c('div',{staticClass:"row"},[_vm._m(9),_c('div',{staticClass:"col-8 text-left"},[_vm._v(_vm._s(_vm.alteradoEmAtual))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Nome")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Tipo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Conta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Telefone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Celular")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("PJ?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("CPF/CNPJ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Criado em")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 text-right"},[_c('strong',[_vm._v("Alterado em")])])
}]

export { render, staticRenderFns }